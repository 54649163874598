<template>
  <VMenu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <slot v-bind="{ on, attrs, rules, formatted, label, ISOString }">
        <VTextField
            dense
            outlined
            hide-details="auto"
            append-icon="mdi-calendar"
            readonly
            @click:append="on.click"
            :value="dateInInput"
            :rules="[...rules, (v) => isDate(v) || 'Такой даты не существует']"
            :label="label"
            v-bind="attrs"
            v-on="on"
        >
          <template #label>
            {{ label }} <span class="error--text" v-if="required">*</span>
          </template>
        </VTextField>
      </slot>
    </template>
    <VDatePicker v-model="date" no-title scrollable :min="min" :max="max">
      <div :class="$style.controls">
        <div class="mb-3">
          <VTextField
              v-model="dateInInput"
              v-mask="'##.##.####'"
              :rules="[
                  (v) => isDate(v) || 'Такой даты не существует'
              ]"
              outlined
              dense
              full-width
              hide-details="auto"
          />
        </div>
        <VBtn color="primary" depressed small @click="$refs.menu.save(date)" v-text="'Применить'" />
        <VBtn color="secondary" class="ml-2" depressed small @click="menu = false" v-text="'Отмена'" />
      </div>
    </VDatePicker>
  </VMenu>
</template>

<script>
import { split, reverse, join, isEmpty } from 'lodash-es';

export default {
  name: 'DateInput',
  props: {
    label: { type: String },
    value: { type: String },
    required: { type: Boolean },
    rules: { type: Array, default: () => [] },
    min: { type: String },
    max: { type: String },
  },
  data() {
    return {
      dateInInput: this.value,
      date: join(reverse(split(this.value, '.')), '-'),
      menu: false,
    }
  },
  computed: {
    formatted() {
      const date = this.date;
      return join(reverse(split(date, '-')), '.');
    },
    ISOString() {
     return this.date;
    }
  },
  methods: {
    isDate(date) {
      return !date || !isNaN(Date.parse(join(reverse(split(date, '.')), '-')));
    },
    isEmpty(date) {
      return isEmpty(date);
    },
  },
  watch: {
    dateInInput(value) {
      if (!value) {
        this.date = '';
      } else if (value.length === 10) {
        const date = join(reverse(split(value, '.')), '-');
        if (this.isDate(date)) {
          this.date = date;
        }
      }
    },
    value: {
      handler(value) {
        if (!value) this.date = '';
        else {
          this.date = join(reverse(split(value, '.')), '-');
        }
      }
    },
    formatted: {
      handler(date) {
        this.dateInInput = date;
        this.$emit('input', date);
      }
    }
  }
}
</script>

<style module lang="scss">
.controls {
  flex: 1 1 auto;
  background: #f5f5f5;
  margin: -8px;
  padding: 8px;
}
</style>
